<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        a) In which of the following conformations do the methyl,
        <stemble-latex content="$\ce{CH3,}$" /> substituents have a
        <stemble-latex content="$\textit{gauche}$" />
        relationship?
      </p>

      <p class="mb-2 pl-12">
        <v-img
          src="/img/assignments/newmanProjectionsButane.png"
          alt="Four different Newman projections are shown. Projection A has the two methyl substituents 180 degrees apart. Projection B has the two methyl substituents 120 degrees apart. Projection C has the two methyl groups overlapping. Projection D has the two methyl groups 60 degrees apart."
          max-width="319px"
        />
      </p>

      <v-radio-group v-model="inputs.multipleChoiceAnswer" :disabled="!allowEditing">
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2 mt-2">b) I think this because:</p>

      <p class="mb-n6">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'LaurierSandboxQ1',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {text: '$\\ce{Projection A}$', value: 'A'},
        {text: '$\\ce{Projection B}$', value: 'B'},
        {text: '$\\ce{Projection C}$', value: 'C'},
        {text: '$\\ce{Projection D}$', value: 'D'},
      ],
    };
  },
};
</script>
